import React from'react'
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import {convertDateToHrsMins, dk_monthNames, dk_weekNames} from '../helpers/DatesTime.Helper'
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stack from '@mui/material/Stack';
import { useDates } from '../contexts/DatePickerProvider';

function TimeDateBar() {

    const {dateValue, clickDateBackwards, clickDateForwards, clickDateToday} = useDates()

    return (
        <>
            {/* Desktop View */}
            <Box justifyContent="flex-end" sx={{ flexGrow: 2, display: { xs: 'none', sm: 'flex' } }}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <IconButton size="large" color="secondary" onClick={clickDateBackwards}>
                        <ArrowBackIosIcon/>
                    </IconButton>
                    <ButtonBase onClick={clickDateToday}> 
                        <Typography variant="h6" >
                            {dk_weekNames[dateValue.getDay()] + " "}
                            {"\n den " + dateValue.getDate() + ". "}
                            {dk_monthNames[dateValue.getMonth()] + " "}
                            {dateValue.getFullYear().toLocaleString().replace(",", "") + " |\xa0"}
                        </Typography>
                        <Typography variant="h6">
                            {convertDateToHrsMins(dateValue)}
                        </Typography>
                    </ButtonBase>
                    <IconButton size="large" color="secondary" onClick={clickDateForwards}>
                        <ArrowForwardIosIcon/>
                    </IconButton>
                </Stack>
            </Box>
            
            {/* Mobile View */}
            <Box justifyContent="flex-end" sx={{ flexGrow: 2, display: { xs: 'flex', sm: 'none' } }}>
                <Stack direction="row" alignItems="center" gap={0}>
                    <IconButton size="small" color="secondary" onClick={clickDateBackwards}>
                        <ArrowBackIosIcon/>
                    </IconButton>
                    <ButtonBase onClick={clickDateToday}> 
                        <Typography variant="subtitle2">
                            {dateValue.getDate() + "-"}
                            {(dateValue.getMonth() + 1)}
                        </Typography>
                    </ButtonBase>
                    <IconButton size="small" color="secondary" onClick={clickDateForwards}>
                        <ArrowForwardIosIcon/>
                    </IconButton>
                </Stack>
            </Box>
        </>

    );

}

export default TimeDateBar;