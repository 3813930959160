import React from'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { PageWrapper } from './Wrappers.styles'
import EventInfo from '../components/EventInfo'

function Gasvaerket() {
    return (
        <>
            <NavBar/>
                <PageWrapper>
                    <EventInfo/>
                </PageWrapper>
            <Footer/>
        </>

    )
}

export default Gasvaerket;