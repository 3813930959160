var apiURL = "https://api.dash.theater.umakers.io"
// var apiURL = "http://localhost:3001"

export function getYesplanData(route) {
  return fetch(apiURL + `/yesplan/${route}`)
    .then(function (res) {
      return res.json();
    })
    .catch((error) => {
      throw error;
    });
}

export function mergeEventsSchedulesCustomdata(events, schedules, customdata) {
  const eventsAndSchedules = events.map((a) =>
    Object.assign(
      a,
      schedules.find((b) => b.event.id === a.id)
    )
  );
  const eventsSchedulesCustomData = eventsAndSchedules.map((a) =>
    Object.assign(
      a,
      customdata.find((b) => b.event.id === a.id)
    )
  );
  return eventsSchedulesCustomData;
}

export default getYesplanData;
