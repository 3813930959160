const wifiCode = "WIFI (Gaest): teaterHatten! | "
const contactInfo = "CONTACT: "
const ØG_Production = "Production - Markus Schulin Zeuthen (26 99 28 58)"
const ØG_Maintenance = "Maintenance - Finn H. Storgaard (31 65 30 33)"
const REPREV_Production = "Produktionschef - Svend Holst (60 65 85 33)"
const REPREV_Maintenance = "Drift - Kenneth Kreutzfeldt (40 88 24 26)"

export function findLocation(pathname) {
    if (pathname === '/') {return østerbroTeater}
    else if (pathname === '/gasvaerk') { return gasvaerket}
    else if (pathname === '/gasvaerk-rehearsal') { return gasrehearsal}
    else if (pathname === '/reprev') { return republiqueRevolver}
    else if (pathname === '/offsites') { return offsites}
    else if (pathname === '/info') { return generalInfo }
    else return emptyPage
}

export const emptyPage = {
    name: "",
    href: "",
    wifi: "",
    contact: "",
}

export const generalInfo = {
  name: "Praktisk information",
  href: "/info",
  wifi: "",
  contact: "",
}

export const østerbroTeater = {
  name: "Østerbro Teater",
  href: "/",
  wifi: wifiCode,
  contact: contactInfo + ØG_Maintenance,
}

export const gasvaerket = {
  name: "Østre Gasværk (Main)",
  href: "/gasvaerk",
  wifi: wifiCode,
  contact: contactInfo + ØG_Production + " / " + ØG_Maintenance,
}

export const gasrehearsal = {
  name: "Østre Gasværk (Extra)",
  href: "/gasvaerk-rehearsal",
  wifi: wifiCode,
  contact: contactInfo + ØG_Production + " / " + ØG_Maintenance,
}

export const republiqueRevolver = {
  name: "Republique & Revolver",
  href: "/reprev",
  wifi: wifiCode,
  contact: contactInfo + REPREV_Production + " / " + REPREV_Maintenance,
}

export const offsites = {
  name: "Off-sites",
  href: "/offsites",
  wifi: "",
  contact: "",
}

export const sitePages = [østerbroTeater, gasvaerket, gasrehearsal, republiqueRevolver, offsites, generalInfo];