import {mergeEventsSchedulesCustomdata} from '../api/YesPlan/yesplanRequests'

export const generalLocationIDs = [
    {id: "129203969-1682593977", name: "General Info", prio: 1},
    {id: "99999999999999999999", name: "No Location", prio: 2}
]

export const ØGMainIDs = [
    {id: "603691009-1677676242", name: "ØG Stage", prio: 1}, 
    {id: "603685889-1677676242", name: "ØG Studio 1", prio: 2}, 
    {id: "312948481-1699519957", name: "ØG Studio 2", prio: 3}, 
    {id: "1376485377-1693230834", name: "ØG Foyer", prio: 4}, 
]

export const ØGSecondaryIDs = [
    {id: "1242218753-1707476945", name: "ØG AD Office", prio: 1}, 
    {id: "795548673-1680507148", name: "ØG Canteen", prio: 2}, 
    {id: "1447939329-1707825704", name: "ØG Frisørsalon", prio: 3},
    {id: "1449891585-1707825708", name: "ØG Skræddersal", prio: 4},
]

export const REPREVLocationIDs = [
    {id: "603689729-1677676242", name: "REP Scene", prio: 1}, 
    {id: "603687169-1677676242", name: "REP Foyer", prio: 2}, 
    {id: "603688449-1677676242", name: "REV Scene", prio: 3}, 
    {id: "603684609-1677676242", name: "REV Foyer", prio: 4}
]

export const offsiteLocationIDs = [
    {id: "652146433-1680506772", name: "ØG Off-site", prio: 1}, 
    {id: "552082433-1698227237", name: "REP/REV Off-site", prio: 2}, 
]

export function determineEventLocation(location: any) : any[] {

    if (location.pathname === '/gasvaerk') {
        return ØGMainIDs
    }

    if (location.pathname === '/gasvaerk-rehearsal') {
        return ØGSecondaryIDs
    }

    if (location.pathname === '/offsites') {
        return offsiteLocationIDs
    }

    else if (location.pathname === '/reprev') {
        return REPREVLocationIDs
    }

    else if (location.pathname === '/') {
        return generalLocationIDs
    }
    else return [""]
}

export function filterEventsByPageLocation(events: any, schedules: any, customdata: any, location: any) : any {
    // Extract IDs from the Yesplan location objects containing {id, name, prio}
    let currentYesplanLocationIDs = determineEventLocation(location).map(item => item['id'])

    // Filters all events fetched from API into the locations determined by the current page the user is at (e.g ØT, ØG or REPREV)
    // Sort all events by start time using string ISO format
    let currentEventsByPageLocation = mergeEventsSchedulesCustomdata(
        (events
            .filter((event: { locations: any[]; }) => event.locations.every((location: { id: string }) => currentYesplanLocationIDs.includes(location.id)))), schedules, customdata)
            .sort( (a: { starttime: string; }, b: { starttime: string; }) => (a.starttime > b.starttime) ? 1 : (((a.starttime < b.starttime) ? -1 : 0)))

    // Creates Array of Objects containing Yesplan Location IDs, Name and Priority (.prio)
    // Priority is used to specify the order of elements in the frontend display (e.g. Stage | Foyer | Rehearsal Room | Other)
    let arrayLocationAndEvents = determineEventLocation(location)

    // Creates a new Array sorted by Priority and with an empty Events Array.
    let mapLocationsEventsCustomData : any = arrayLocationAndEvents.map((currEvent) => ({prio: currEvent.prio, id: currEvent.id, name: currEvent.name, events:[]}))

    // Matches Locations to Events
    for (var i = 0 ; i < currentEventsByPageLocation.length ; i++) {
        if(currentEventsByPageLocation[i].locations.length === 0) {
            currentEventsByPageLocation[i].locations.push({id: "99999999999999999999", name: "No Location"})
        }

        const index = mapLocationsEventsCustomData.findIndex((e : any) => e.id === currentEventsByPageLocation[i].locations[0].id);
        if (index !== -1) {
            mapLocationsEventsCustomData[index].events.push(currentEventsByPageLocation[i])
        }
    }

    return mapLocationsEventsCustomData
}

export default determineEventLocation;
