import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ClockImage from '../assets/symbols/clock-yesplan.png'

import { useTheme } from '@mui/material/styles';

export default function EmptyCard(data: any) {

  const theme = useTheme();

  const card = (
    <React.Fragment>
      <CardContent>

        {/* EVENT NAME */}
        <Typography sx={{typography: "h6"}} component="div" >
        No events planned
        </Typography>

        {/* EVENT TIME */}
        <Typography>
          <img alt="clock" src= {ClockImage} width="13"/> 
          {" 06:00-00:00"} 
        </Typography>
      </CardContent>
    </React.Fragment>
  );
  
  return (
    <Box minWidth={300} maxWidth={300}>
      <Card
            sx={{
            backgroundColor:theme.palette.background.default, 
            color:theme.palette.primary.main,
            }}>
        {card}
      </Card>
    </Box>
  );
}