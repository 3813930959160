import React from 'react'
import Box from '@mui/material/Box';
import Test01 from '../assets/PDFs/Info-samlet_1920x1080_V1.png'
import Test02 from '../assets/PDFs/Info-samlet_1920x1080_V12.png'
import Test03 from '../assets/PDFs/Info-samlet_1920x1080_V14.png'

const pdfHeight = '100%';
const pdfWidth = '100%';
const pdfmaxHeight = { xs: 640, md: 1080 }
const pdfmaxWidth = { xs: 1136, md: 1920 }

const PDFViewer = () => {

 return (
    <>
        <Box
            component="img"
            sx={{
                height: pdfHeight,
                width: pdfWidth,
                maxHeight: pdfmaxHeight,
                maxWidth: pdfmaxWidth,
            }}
            alt="Test pdf"
            src={Test01} />
        
        <Box
            component="img"
            sx={{
                height: pdfHeight,
                width: pdfWidth,
                maxHeight: pdfmaxHeight,
                maxWidth: pdfmaxWidth,
            }}
            alt="Test pdf"
            src={Test02} />

        <Box
            component="img"
            sx={{
                height: pdfHeight,
                width: pdfWidth,
                maxHeight: pdfmaxHeight,
                maxWidth: pdfmaxWidth,
            }}
            alt="Test pdf"
            src={Test03} />
    </>

    
 )
}

export default PDFViewer