import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import {useLocation} from 'react-router-dom';
import TimeDateBar from './TimeDateBar'
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {findLocation, sitePages} from '../data/PagesInfo'

function ResponsiveAppBar() {
  const [pageLocation, setPageLocation] = React.useState<null | HTMLElement>(null);
    
  const handleOpenPageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPageLocation(event.currentTarget);
  };
  const handleClosePageMenu = () => {
    setPageLocation(null);
  };

  const location = useLocation();
  const theme = useTheme();
  const currentLocation = findLocation(location.pathname)

  return (
    <AppBar sx={{ color: theme.palette.primary.main, background: theme.palette.background.default }} position="fixed">
      <Container maxWidth={false} >
        <Toolbar disableGutters>

          {/* Page Menu */}
          <Box>
            <IconButton
              size="large"
              aria-label="location of current page"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenPageMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={pageLocation}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(pageLocation)}
              onClose={handleClosePageMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {sitePages.map((page) => (
                <MenuItem key={page.name} onClick={handleClosePageMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Current Location */}
          <Typography
            variant="h6"
            noWrap
            sx={{
              typography: { xs: 'subtitle2', sm: 'h6'},
              mr: 2,
              fontFamily: 'Graebenbach Mono',
              color: 'inherit',
              textDecoration: 'none',

            }}
          >
            {currentLocation.name}
          </Typography>
            
          {/* Location Info */}
          <Typography variant="subtitle1" sx={{ display: { flexGrow: 2, xs: 'none', sm: 'none', md: 'flex' }}}>
           {currentLocation.wifi} {currentLocation.contact}
          </Typography>

          {/* Date and Time */}
          <TimeDateBar />

          {/* Page Dropdown Menu */}
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={pageLocation}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(pageLocation)}
              onClose={handleClosePageMenu}
            >
              {sitePages.map((page) => (
                <MenuItem key={page.name} onClick={handleClosePageMenu}>
                    <Link href ={page.href}>
                        <Typography color={theme.palette.background.default} textAlign="center">{page.name}</Typography>
                    </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>   

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;