import React from'react';
import { CornerRightLogo, CornerRightQRCode } from '../styles/Image.styles'
import oetlogo from "../assets/logos/Osterbroteater.png";
import WebsiteQRCode from '../assets/QR/QR - Website.svg'

function Footer() {
    return (
        <>
            <CornerRightQRCode className="oetqrcode" src={WebsiteQRCode} width="83"  alt="Østerbro Teater - Website QR Open"/>  
            <CornerRightLogo className="oetlogo" src={oetlogo} width="150" alt="Østerbro Teater Logo"/>  
        </>
    );
}

export default Footer;