// Helper functions for converting Date objects

export function formatDateToYesplanDateString(date: Date) {
  return date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
}
export function convertDateToHrsMins (date: Date) {
    return date.toLocaleTimeString(dk_countryCode, { hour: '2-digit', minute: '2-digit' })}

export function parseTimeToHrsMins(time: string) {
    var hoursAndMinutes = new Date(time).toLocaleTimeString(dk_countryCode, {
      hour: '2-digit',
      minute: '2-digit',
  });
    return hoursAndMinutes
  }

// Translations for local country code, month and weeknames for formatting Date objects
export const dk_countryCode = 'da-DK'

export const dk_monthNames = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];

export const dk_weekNames = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag" ]
