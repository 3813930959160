import React, { createContext, useContext, useState } from 'react';
import { DatePickerContextType, DatePickerProviderProps } from '../types/DateProvider.interface';

export const DateContext = createContext<DatePickerContextType | undefined>(undefined)

export function DatePickerProvider ({children}: DatePickerProviderProps) {
  const [dateValue, setDateValue] = useState(new Date())
    
  function clickDateForwards() {
      const newDate = new Date(dateValue)
      newDate.setDate(dateValue.getDate() + 1)
      setDateValue(newDate)
  }

  function clickDateBackwards() {
      const newDate = new Date(dateValue)
      newDate.setDate(dateValue.getDate() - 1)
      setDateValue(newDate)
  }

  function clickDateToday() {
    setDateValue(new Date())
  }

  const contextValue: DatePickerContextType = { 
    dateValue, 
    clickDateForwards, 
    clickDateBackwards,
    clickDateToday,
  }

  return (
    <DateContext.Provider value={contextValue}>
        {children}
    </DateContext.Provider>
  );
};

export function useDates() {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDates must be used within a DateProvider');
  }
  return context;
};
