import styled from "styled-components";

export const PageWrapper = styled.div`

margin-top: 10%;
margin-left: 2.5%;
margin-right: 2.5%;

@media screen and (max-width: 900px) {
    margin-top: 25%;
}

`;