import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ClockImage from '../assets/symbols/clock-yesplan.png'
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { parseTimeToHrsMins } from '../helpers/DatesTime.Helper';

export default function EventCard(data: any) {

  const theme = useTheme();
  const groupExists = data.data.group
  const scheduleEntries = data.data.entries !== undefined 
  const eventStartTime = data.data.defaultschedulestarttime !== null ? data.data.defaultschedulestarttime : "ALL DAY"
  const eventEndTime = data.data.defaultscheduleendtime !== null ? "-" + data.data.defaultscheduleendtime : ""
  const eventTime = eventStartTime + eventEndTime
  // const locationName = data.data.locations.length !== 0 ? data.data.locations[0].name : "No Location"
  const statusName = data.data.status.name
  const profileName = data.data.profile.name
  const customDataExists = data.data.groups !== undefined 
  const findDailyInformationTab = customDataExists ? data.data.groups.find((infotabs: { keyword: string; }) => {return infotabs.keyword === "dailyinformation"}) : false
  const dailyInfoHasValue = customDataExists ? findDailyInformationTab.children[0].value !== null ? true : false : false
  const yesplanLink = data.data.id !== undefined ? "https://yesplan.app/be/osterbroteater/event/" + data.data.id : ""

  const card = (
    <React.Fragment>
      <CardContent>

        {/* EVENT NAME */}
        <Typography sx={{typography:{sm: "h6", xs:"body1"}}} component="div" >
        {data.data.name}
        </Typography>

        {/* EVENT TIME + GROUP NAME */}
        <Typography sx={{typography:{sm: "body1", xs:"body2"}}}>
          <img src= {ClockImage} alt="clock" width="13"/> {" " + eventTime} <br/>
          {groupExists ? groupExists.name : "NO GROUP"}
        </Typography >

        {/* STATUS + PROFILE */}
        <Typography sx={{typography:{fontStyle: 'italic', sm: "body1", xs:"body2"}}}>
          {statusName} <br/>
          {profileName} <br/>
        </Typography >

        {/* DIVIDER BEFORE DAILY INFORMATION */}
        {dailyInfoHasValue && <Divider flexItem sx={{ bgcolor:theme.palette.primary.main, mt: 1, mb: 1, borderBottomWidth: 0.5}}/>}

        {/* DAILY INFORMATION */}
        {/* Search for customdata within the Daily Information tab and format it including line breaks using 'pre-line' */}
        {customDataExists ? findDailyInformationTab.children.map((entry: any, index: number) => {
                return <Typography sx={{typography:{sm: "body1", xs:"body2"}}} style={{whiteSpace: 'pre-line'}} key={index}> {entry.value} </Typography>}) 
                : <></>}
        
        {/* DIVIDER BEFORE SCHEDULE */}
        <Divider flexItem sx={{ bgcolor:theme.palette.primary.main, mt: 1, mb: 1, borderBottomWidth: 0.5}}/>

        {/* SCHEDULE TIMES & DESCRIPTION */}
        {scheduleEntries ? data.data.entries.map((entry: any, index: number) => {
          return  <Typography sx={{typography:{sm: "body1", xs:"body2"}}} style={{ marginBottom: "4px" }} key={index}> {parseTimeToHrsMins(entry.starttime)} - {entry.description} </Typography>}) 
                : <Typography> NO SCHEDULE</Typography>}

      </CardContent>
    </React.Fragment>
  );
  
  return (
    <Box minWidth={300} maxWidth={300}>
      <Card
            sx={{
            backgroundColor:theme.palette.background.default, 
            color:theme.palette.primary.main,
            }}>
          <CardActionArea href={yesplanLink} target="_blank" rel="noopener noreferrer">
              {card}
          </CardActionArea>
            
      </Card>
    </Box>
  );
}