import styled from 'styled-components'

export const CornerRightLogo = styled.img`
@media screen and (max-width: 900px) {
    width: 100px;
}

    position: fixed;
    bottom: 0px; 
    right: 0px;
    margin-right: 1rem;
    margin-bottom: 1rem;
`;


export const CornerRightQRCode = styled.img`

@media screen and (max-width: 900px) {
    display: none;
}

@media screen and (min-width: 900px) {
    width: 100px;
}
    position: fixed;
    bottom: 35px; 
    right: 0px;
    margin-right: 1rem;
    margin-bottom: 1rem;
`;