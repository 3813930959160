import React, {useState, useEffect, useMemo, useContext, useCallback, createContext} from'react'
import Divider from '@mui/material/Divider';
import EventCard from './EventCard'
import {useLocation} from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { filterEventsByPageLocation } from '../helpers/Eventinfo.Helper';
import EmptyCard from './EmptyCard';
import { getYesplanData } from '../api/YesPlan/yesplanRequests';
import { useDates } from '../contexts/DatePickerProvider'
import {formatDateToYesplanDateString} from '../helpers/DatesTime.Helper'

// import useMediaQuery from '@mui/material/useMediaQuery';
// import { ResponsiveDivider } from './ResponsiveDivider';

const timeBetweenFetches = 100000;

function EventInfo() {
    const [events, setEvents] = useState<any[]>([])
    const [schedules, setSchedules] = useState<any[]>([])
    const [customData, setCustomData] = useState<any[]>([])
    const [locationsEventsSchedulesCustomdata, setLocationsEventsSchedulesCustomdata] = useState<any[]>([])

    const location = useLocation() 
    const theme = useTheme();
    const {dateValue} = useDates()

    const fetchYesplanData = useCallback(() => {
        getYesplanData(`events/:?date=${formatDateToYesplanDateString(dateValue)}`).then((events) => {
            setEvents(events.data)
        })
        getYesplanData(`events/schedule/:?date=${formatDateToYesplanDateString(dateValue)}`).then((schedules) => {
            setSchedules(schedules.data)
        })
        getYesplanData(`events/customdata/:?date=${formatDateToYesplanDateString(dateValue)}`).then((customdata) => {
            setCustomData(customdata.data)
        })
    }, [dateValue])

    // API data fetch from Yesplan every [timeBetweenFetches] milliseconds
    useEffect(() => {
        fetchYesplanData()
        const interval = setInterval((id)=>{
            fetchYesplanData()
            },timeBetweenFetches)
        return() => clearInterval(interval)

    }, [dateValue, fetchYesplanData])

    useMemo(() => {
        setLocationsEventsSchedulesCustomdata(filterEventsByPageLocation(events, schedules, customData, location))
    },[events, schedules, customData, location])

    return (
        <>
            {/* TODO Horizontal eventskifte responsive mobil? */}
                <Grid container spacing={8} justifyContent="center" >

                    {/* LOCATION COLUMN */}
                    {locationsEventsSchedulesCustomdata.map((location, index1: number) => (
                        <>
                            <Grid key={index1} direction="column" container display="flex" flex-wrap="wrap" justifyContent="flex-start">
                            {/* LOCATION NAME */}
                                <Typography align="center" sx={{typography: {sm: "h4", xs:"h5"}, marginTop:"25px", color:theme.palette.secondary.main}}> {location.name}</Typography>

                                
                                    {/* EVENT PER LOCATION CARD */}
                                    {/* TODO Remove location entirely if none are there */}
                                    {/* If no events exist on the given day, display a empty event card */}
                                    {(location.events.length < 1) ? <EmptyCard/> : <></>}

                                    {/* If there events exists, print each */}
                                    {location.events.map((eventSchedule: any, index2: number) => (
                                        <Grid key={index2} > 
                                            <EventCard flex="auto" key={index2} data={eventSchedule} /> 
                                        </Grid>
                                    ))}

                            </Grid>

                            {/* DIVIDER AFTER EACH LOCATION (with if-else to avoid extra line after last column) */}
                            {
                                (index1 < locationsEventsSchedulesCustomdata.length -1) ? 
                                <Grid>
                                {/* <ResponsiveDivider orientation={{xs: "horizontal", sm: 'vertical'}} sx={{ bgcolor: '#FFFFFF', borderRightWidth: 2}} /> */}
                                    <Divider orientation="vertical" sx={{ display: { xs: 'none', sm: 'flex' }, bgcolor: 'white', borderRightWidth: [0,1,2]}} />
                                </Grid> 
                                : <Grid></Grid>
                            }
                        </>
                    ))}
                </Grid>
        </>
    )
}

export default EventInfo
