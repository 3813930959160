import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';

export let theme = createTheme({
  palette: {
    background: {
      default: '#213540',
    },
    primary: {
      main: '#f3ebde',
    },
    secondary: {
      main: '#c2986b'
    },
    info: {
      main: '#76a5af'
    }
  },
  typography: {
      fontFamily: ['Graebenbach Mono', 'Graebenbach', 'Rosart', 'Glossy Display'].join(','),
      fontSize: 11,
  },
  // TODO Add or remove shadows around boxes and events?
  shadows: shadows.map(() => 'none') as typeof shadows,
})

theme = responsiveFontSizes(theme)
