import React from'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import OsterbroTeater from './pages/ØsterbroTeater';
import Gasvaerket from './pages/Gasvaerket';
import RepubliqueRevolver from './pages/RepubliqueRevolver';
import GeneralInfo from './pages/GeneralInfo'
import GasRehearsal from './pages/GasRehearsal'
import Offsites from './pages/Offsites'
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';
import {østerbroTeater, gasvaerket, gasrehearsal, republiqueRevolver, offsites, generalInfo} from './data/PagesInfo'
import {theme} from './styles/theme'
import { DatePickerProvider } from './contexts/DatePickerProvider';

function App() {

  return (

        <ThemeProvider theme={theme}>
          <CssBaseline />
            <BrowserRouter>
              <DatePickerProvider>

                <Routes>
                  
                  <Route
                    path= {østerbroTeater.href}
                    element={
                        <OsterbroTeater />
                    }
                  />
                  <Route
                    path= {gasvaerket.href}
                    element={
                        <Gasvaerket />         
                      }
                  />
                  <Route
                    path= {gasrehearsal.href}
                    element={
                        <GasRehearsal />         
                      }
                  />
                  <Route
                    path= {republiqueRevolver.href}
                    element={
                        <RepubliqueRevolver />
                    }
                  />

                  <Route
                    path= {offsites.href}
                    element={
                        <Offsites />
                    }
                  />

                  <Route
                    path= {generalInfo.href}
                    element={
                        <GeneralInfo />
                    }
                  />

              </Routes>
            </DatePickerProvider>
          </BrowserRouter>
        </ThemeProvider>
  );
}

export default App;