import React from'react'
import NavBar from '../components/NavBar'
import PDFViewer from '../components/PDFViewer'

function GeneralInfo() {
    return (
        <>
            <NavBar/>
            <PDFViewer/>
        </>
    )
}

export default GeneralInfo;